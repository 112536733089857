import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import BlogItem from './sidebar-blog-item';
import { SimilarItemsFactory } from './similar-items-factory';
import Utils from '../../utils';

const SimilarTestimoniesItems = ({ items }) => {
  if (!items || items.length === 0) {
    // Show nothing, a placeholder, or spinner while loading
    return null; 
  }
  return (
    <section className="similar-items">
      {items.map((item, i) => {
        let description = Utils.trimToN(item?.item?.body?.summary, 75, 3);
        return (
          <BlogItem
            key={i}
            title={item?.item?.title}
            description={description}
            date={item?.item?.created}
            path={item?.item?.path?.alias}
            image={
              item?.item?.relationships?.field_testimoni_image?.relationships?.field_media_image?.image_style_uri?._294x192 ||
              'https://via.placeholder.com/150'
            }
            className="similar-item"
            nodeType={item?.internal?.type}
          />
        );
      })}
    </section>
  );
};

// StaticQuery with conditional rendering
export default (props) => (
  <StaticQuery
    query={graphql`
      query SimilarTestimonies {
        posts: allNodeTestimony(limit: 1000) {
          nodes {
            internal {
              type
            }
            langcode
            title
            created(formatString: "DD MMMM YYYY")
            body {
              summary
            }
            path {
              alias
            }
            relationships {
              field_category: field_campaign {
                name
                path {
                  alias
                }
              }
              field_testimoni_image {
                relationships {
                  field_media_image {
                    image_style_uri {
                      _294x192
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      // Avoid rendering anything until data is ready
      if (!data || !data.posts || !data.posts.nodes || data.posts.nodes.length === 0) {
        return null; // Return null to show nothing while data is loading
      }

      const { category, tags, currentItemSlug, lang } = props;

      const getPostsFromQuery = (posts) => {
        return posts?.nodes || [];
      };

      // Marshal the response into items
      const items = getPostsFromQuery(data.posts);

      // Use SimilarItemsFactory to get similar items
      const similarItems = new SimilarItemsFactory(
        items, currentItemSlug, lang
      )
      .setMaxItems(4)
      .setCategory(category)
      .setTags(tags)
      .setLanguage(lang)
      .getItems();


      // Render the SimilarTestimoniesItems component
      return <SimilarTestimoniesItems items={similarItems} />;
    }}
  />
);
